import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { Helmet } from "react-helmet"

import Header from "../components/header"

import "./reset.css"
import "./fonts.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const appear = useSpring({
    delay: 250,
    from: { opacity: 0 },
    to: { opacity: 1 },
    // duration: 500,
  })

  return (
    <Wrapper style={appear}>
      <Helmet title="Drop | Goldfinch" defer={false} />
      <Header
        register={
          typeof window !== `undefined`
            ? window.location.href.includes("register")
            : false
        }
      />
      <main>{children}</main>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Wrapper = styled(animated.div)`
  padding: 10px 10px 0;
`

export default Layout
