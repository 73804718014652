import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Utlis from "../../consts/Utlis"
import Devices from "../../consts/Devices"
import { Link } from "gatsby"

import LogoImage from "../../images/logo.png"

const Header = ({ register }) => {
  return (
    <Wrapper register={register}>
      {register && (
        <Link to="/">
          <Back>
            <i className="fa fa-chevron-left"></i>
            <span>Back </span>
          </Back>
        </Link>
      )}
      <Logo register={register}>
        <img src={LogoImage} />
      </Logo>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${props => (props.register ? Colors.red : Colors.black)};
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: center;
  padding: 5em;
  font-size: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 2;
`

const Logo = styled.div`
  transform: translateY(20px) translateX(-8px);
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 34px;
    height: 20px;
    background: ${props => (props.register ? Colors.red : Colors.black)};
    bottom: -4px;
    left: 39%;
    z-index: -1;
  }
  img {
    width: 70px;
  }
`

const Title = styled.h1`
  color: #fff;
  ${Utlis.fontSize(14, 25, "800px")}
`
const Back = styled.div`
  ${Utlis.fontSize(14, 16, "800px")}
  font-family: "Work Sans", sans-serif;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  cursor: pointer;
  span,
  i {
    color: #ffffff;
  }

  i {
    font-size: 12px;
    margin-right: 5px;
  }
`

export default Header
