function stripUnit(val) {
  return val.replace(/[^-\d\.]/g, "")
}

export default {
  fontSize: (min, size, limit) => {
    let baseline = `375px`
    let fontMultiplier = (size - min) / (stripUnit(limit) - stripUnit(baseline))
    let fontBaseline = min - fontMultiplier * stripUnit(baseline)

    return `
        font-size: ${min}px;
        line-height: 1.5;

        @media (min-width: ${baseline}) {
            font-size: ${min}px;
            font-size: calc(${fontMultiplier} * 100vw + ${fontBaseline}px);
        }
        
        @media (min-width: ${limit}) {
            font-size: ${size}px;
            line-height: ${size * 1.5}px;
        }
    `
  },
}
